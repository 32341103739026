@import '../../styles/index';

.base {
  isolation: isolate;
  margin-inline: auto;
  overflow: hidden;
  padding-left: 1.25rem;
  padding-block: 6rem;
  position: relative;
  width: min(75.5rem, 100%);

  @include media-from(sm) {
    padding-left: 5rem;

    &:before {
      background: linear-gradient(
        to right,
        transparent 0%,
        $surrogate-3 50%,
        transparent 100%
      );
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }

  @include media-from(md) {
    padding-left: 8.125rem;
  }
}

.iconWrapper {
  display: inline-block;
  height: 3.375rem;
  position: relative;
  transform: rotate(-5deg);
  width: 3.375rem;

  &:before {
    background: linear-gradient(
      90deg,
      $violet-9 -0.94%,
      $red-9 100%
    );
    border-radius: 100vw;
    content: '';
    filter: blur(40px);
    height: 7rem;
    left: 50%;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7rem;
    z-index: -1;
  }

  @include media-from(sm) {
    height: 4rem;
    width: 4rem;
  }
}

.title {
  color: $neutral-12;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-block: 1.625rem 0;
  max-width: 15ch;

  @include media-from(md) {
    font-size: 2.25rem;
    margin-block: 2.185rem 0;
    max-width: 25ch;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.8125rem;
  margin-top: 1.5rem;

  > a {
    height: 3.25rem;

    > svg {
      height: 100%;
    }
  }

  @include media-from(md) {
    flex-direction: row;
    margin-top: 2rem;

    > a {
      width: auto;

      > svg {
        max-height: 3rem;
      }
    }
  }
}

.image {
  filter: drop-shadow(0 0 1.5rem rgba($neutral-12, 0.3));
  height: auto;
  left: max(75%, 15ch);
  max-width: 12rem;
  position: absolute;
  top: 5rem;
  transform: rotate(-8deg);
  z-index: -1;

  @include media-from(xs) {
    left: auto;
    max-width: 16.5rem;
    right: min(15%, 8.125rem);
    top: 6.5rem;
    transform: rotate(10deg);
  }
}
