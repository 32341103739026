@import '../../styles/index';

.base {
  margin: 0;
}

.content {
  position: relative;
  padding: 2rem 1rem 2rem;

  @include media-from(xs) {
    padding: 2.5rem 2rem 2rem;
  }
}

.title {
  font-size: $font-size-28;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 0.75rem;

  @include media-from(xs) {
    font-size: $font-size-34;
    margin-bottom: 1rem;
  }
}

.subtitle {
  display: block;
  font-size: $font-size-20;
  font-weight: $font-weight-light;
  margin-bottom: 1rem;
  line-height: 1.5;

  @include media-from(xs) {
    font-size: $font-size-24;
    line-height: 1.33;
  }
}

.infos {
  margin-bottom: 1rem;

  &-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    font-size: $font-size-18;
    line-height: 1.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid $surrogate-4;
    }

    @include media-from(sm) {
      padding: 1.5rem 0;
      font-size: $font-size-20;
    }

    &-icon {
      display: flex;
      align-items: center;
      color: $surrogate-9;
    }

    &-link {
      color: $surrogate-9;
      text-decoration: underline !important;
    }
  }
}

.coupon-text {
  display: block;
  font-size: $font-size-18;
  margin-bottom: 1rem;
  line-height: 1.5;

  @include media-from(sm) {
    font-size: $font-size-20;
  }
}
