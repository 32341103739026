@import '../../styles/index';

.stage-box {
  width: 100%;
  height: auto;
  padding: 1.5rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;

  @include media-from(xs) {
    padding: 2rem;
  }
}

.title {
  font-weight: $font-weight-bold;
  font-size: $font-size-30;
  line-height: 1.2;
  margin: 0 0 2rem 0;

  .white & {
    color: $surrogate-9;
    @extend %text-linear-gradient;
  }

  .gradient & {
    color: $neutral-0;
  }

  @include media-from(sm) {
    font-size: $font-size-48;
  }
}

.form {
  position: relative;

  & > *:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.submit-button {
  margin-top: 1.5rem;

  @include media-from(sm) {
    margin-top: 1.875rem;
  }
}
