@import '../../styles/index';

.base {
  width: 100%;
  height: auto;
  padding: 2rem 1rem 0;
  display: flex;
  flex-direction: column;
  color: $neutral-12;
  background-color: $neutral-0;

  @include media-from(sm) {
    padding: 2.5rem 2rem 1rem 2rem;
  }
}

.title {
  font-size: $font-size-28;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-block-start: 0;
  margin-block-end: 0.5rem;

  @include media-from(md) {
    font-size: $font-size-34;
    margin-bottom: 1rem;
    margin-block-end: 1rem;
  }
}

.infos {
  margin-bottom: 1rem;
}

.info-item {
  padding: 1rem 1.5rem 1rem 0;
  font-size: $font-size-18;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  line-height: 1.5rem;

  &:not(:last-child) {
    border-bottom: 1px solid $surrogate-4;
  }

  @include media-from(md) {
    font-size: $font-size-20;
    padding: 1.5rem 1.5rem 1.5rem 0;
  }
}

.info-item-link {
  display: block;
  a {
    display: flex;
    align-items: center;
    color: $surrogate-9;
    font-weight: $font-weight-medium;
  }
  @include media-from(md) {
    display: none;
  }
}

.info-item-icon {
  display: flex;
  align-items: center;
  color: $surrogate-9;
  width: 1.5rem;
  height: 1.5rem;
  contain: strict;
}

.info-item-link-icon {
  color: $surrogate-9;
}

.info-item-text {
  margin-left: 1rem;
}

.button {
  margin: 0;

  @include media-to(xsbetween) {
    flex: 1;
  }

  @include media-from(md) {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footnote {
  font-size: $font-size-14;
  color: $surrogate-7;
  line-height: 1.5;
  text-align: left;

  @include media-from(md) {
    font-size: $font-size-16;
    margin-bottom: 1rem;
  }
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  gap: 1.5rem 1rem;
  align-items: center;

  @include media-from(md) {
    gap: 1.5rem;
  }
}

.helper-text {
  color: $neutral-12;
  font-size: $font-size-18;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 0.5rem;

  @include media-from(xs) {
    font-size: $font-size-20;
    margin: 0 0 1rem;
  }
}

.job-id {
  @extend %text-linear-gradient;
  width: fit-content;
  margin: 0 0 0.75rem;

  padding: 0;

  font-weight: $font-weight-medium;
  font-size: $font-size-18;
  line-height: 1.2;

  @include media-from(md) {
    font-size: $font-size-20;
    margin: 0 0 1rem;
  }
}

.apply-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-from(md) {
    flex-direction: row;
  }
}

.apply-button {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @include media-from(md) {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.75rem;
    }
  }
}
