@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $neutral-0;
  border-radius: $border-radius-large;
  box-shadow: $shadow-benefits-deal-card;
  overflow: hidden;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  margin: auto;

  picture {
    line-height: 1;
  }

  img {
    position: relative;
    max-width: 6.25rem;
    max-height: 2.5rem;
    height: auto;
    object-fit: contain;
  }
}

.image-wrapper {
  position: relative;
  height: 11.25rem;

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.title-wrapper {
  padding: 1.25rem 1.25rem 1.5rem;

  .title {
    font-weight: $font-weight-bold;
    font-size: $font-size-20;
    text-align: center;
    line-height: 1.4;
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($neutral-0, 0.9);
  z-index: 1;
  color: $neutral-12;

  &-text {
    font-size: $font-size-20;
    font-weight: $font-weight-bold;
    line-height: 1.4;
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 3.125rem;
  padding: 0 0.5rem;
  background-color: rgba($turquoise-9, 0.8);
  backdrop-filter: blur(0.25rem);
  z-index: 1;

  &-text {
    font-size: $font-size-14;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    color: $neutral-0;
    line-height: 1.3;
    letter-spacing: 0.063rem;
  }
}
