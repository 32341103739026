@import '../../../styles/index';

.base {
  display: block;
  font-size: $font-size-20;
  font-weight: $font-weight-light;
  margin-bottom: 1rem;
  line-height: 1.5;

  @include media-from(xs) {
    font-size: $font-size-24;
    line-height: 1.33;
  }
}
