@import '../../../styles/index';

.base {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  font-size: $font-size-18;
  line-height: 1.5rem;

  .content {
    a {
      color: $surrogate-9;
      text-decoration: underline;
    }
  }

  @include media-from(sm) {
    padding: 1.5rem 0;
    font-size: $font-size-20;
  }
}

.icon {
  display: flex;
  align-items: center;
  color: $surrogate-9;
}
