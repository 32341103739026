@import '../../../styles/index';

.base {
  font-size: $font-size-28;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 0.75rem;

  @include media-from(xs) {
    font-size: $font-size-34;
    margin-bottom: 1rem;
  }
}
