@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
}

.headline {
  display: inline-block;
  font-size: $font-size-30;
  font-weight: $font-weight-medium;
  line-height: 1.25;
  margin-bottom: 0;

  @extend %text-linear-gradient;

  @include media-from(sm) {
    font-size: $font-size-36;
    line-height: 1.1;
  }
}

.wrapper {
  display: flex;
  overflow-x: scroll;
  gap: 1.25rem;
  transition: transform 0.25s ease;
  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
  padding: 2rem 1rem 2rem;
  margin: 0 -1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-from(sm) {
    padding: 3rem 1rem 1.5rem;
  }
}

.item,
.overview-link {
  flex: 0 0 19.0625rem;
  scroll-snap-align: center;

  & > a > div {
    box-shadow: $shadow--small;
  }

  @include media-to(xs) {
    flex: 0 0 17rem;
  }
}

.overview-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $gradient-enercity;
  border-radius: 0.75rem;
  padding: 2rem;
  box-shadow: $shadow--small;

  &-text {
    display: flex;
    align-items: center;
    color: $neutral-0;
    font-size: $font-size-24;
    font-weight: $font-weight-medium;
    text-align: center;
    flex: 1;
  }

  &-button {
    min-width: 100%;
    margin-top: auto;
  }
}
