@import '../../styles/index';

.base {
  align-items: center;
  background-color: $surrogate-2;
  border: 1px solid $surrogate-4;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  min-height: 3.125rem;
  padding: 0.75rem 1rem;

  @include media-from(sm) {
    border-radius: 0.5rem;
    display: inline-block;
    min-height: 3.75rem;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
  }
}

.text {
  color: $surrogate-9;
  font-size: $font-size-18;
  line-height: 1.3;

  @include media-from(sm) {
    font-size: $font-size-20;
    line-height: 1.2;
  }
}
